import React, {useState} from 'react'
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'

import './navbar.css'

const Menu = () =>(
  <>
    <p><a href='#home'>Home</a></p>
    <p><a href='#projects'>Projects</a></p>
    <p><a href='#about'>About Me</a></p>
    <p><a href='#connect'>Connect</a></p>
  </>
)

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className='navbar'>
      {/* wider screen menu */}
      <div className='navbar-name'>
        <h1>Isaiah Malcom</h1>
      </div>
      <div className='navbar-links_container'>
        <Menu/>
      </div>
      {/* small screen drop down */}
      <div className='navbar-menu'>
        {toggleMenu ? 
        <RiCloseLine color='#080808' size={27} onClick={()=>setToggleMenu(false)}/>
        : <RiMenu3Line color='#080808' size={27} onClick={()=>setToggleMenu(true)}/>
        }
        {/* if toggle menu is true show navbar */}
        {toggleMenu && (
          <div className='navbar-menu_container scale-up-center'>
            <div className='navbar-menu_container-links'>
              <Menu/>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default Navbar