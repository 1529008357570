import React from 'react'
import {isaiah} from '../index'
import './aboutme.css'

const Aboutme = () => {
  return (
    <div className='aboutme' id='about'>
        <h1>About Me</h1>
        <div className='aboutme_container'>
            <div className='aboutme_container-document'>
            <p>     I am 21 years old I am from a town up north in New Mexico called Espanola. I like to try new things and I can be pretty social at times. I am very interested in machine learning and robotics. I would like to eventually work in these areas. I’ve spent time and money working on projects to be able increase my skill and familiarity in these areas. I enjoy making things both in an artistic sense and definitely in a functional utilitarian sense. I enjoy learning about complex things and find them amazing at times. My family has supported me a lot with my education and goals, and I greatly appreciate them for doing so. </p>
            <p>     I enjoy work a lot, it gives me a sense of self worth and I want to work hard. I have a great work ethic and always want to complete my tasks in a timely manner. I’ve spent many years trying to improve various skills and have focused a lot on software development to be able to bring value. I hope we get the chance to work together or even have a discussion.</p>
            </div>
            <div>
                <img src={isaiah}/>
            </div>
        </div>
    </div>
  )
}

export default Aboutme