import React, {useState} from 'react'
import './projects.css'
import {tableEdit, tableTop, pcb, knife} from '../index'
import Growtile from '../../complexcomponents/growtile/Growtile'

const Projects = () => {
  let information = {
    title: "LED Tables",
    text0: "  Right before I started college and a while after I had picked up forging as a hobby. I came up with the idea of what If I can have a table that has an animation like display. I knew that people would put LED strips under tables, but I had never seen any in a display like sense. With all of this in mind I thought it would be really cool to see this project come to life.",
    text1: "  With my knowledge in metal work, I build a frame as the base. For the first working table top I ended up using singular Red LEDs and wired them to a bunch of multiplexer chips on a big PCB. It wasn’t a super great way to implement this and should have looked into already made programmable strips (which I did use later), but I wanted to get this project going. The table top itself was an epoxy table encasing some burnt wood. I didn’t know anything much about epoxy at the time and working so the final product ended up with a lot of bubbles trapped inside. In fact, I made two tabletops with burnt wood encased but the first one came out so bad I decided to scrap it and try again.",
    text2: "  Once I had all the parts attached and wired, I used an Arduino Uno to write out different animations for it. I was even able to get the whole LED display to be capable of changing brightness, by rendering the whole display fractions of a second tricking the eye for its brightness level.  I then designed and printed out a small case to house a few buttons and a small LCD display to control what animations would be played and other variables. ",
    img:  tableTop
  }

  let tileOne = {
    title: 'LED Tables',
    text: 'Made multiple table tops that use LEDs as a display ran on arduino platform.'
  }

  let tileTwo = {
    title: "Forging & Metalwork",
    text: "I have about a year and a half experience with various metal forming processes."
  }
 
  let tileThree ={
    title: "Work Experience",
    text: "Past employment and side hustles."
  }

  let tileFour = {
    title: 'Computer Science Background',
    text: 'My different interests and expertise in the Computer Science Spectrum.'
  }

  let tileFive = {
    title: 'This Website',
    text: 'My first custom desgined website.'
  }


  let bigTileTwo = {
    title: "Forging & Metalwork",
    text0: "  At the age of 17 I saw a lot of YouTube videos of different people forging knives, tongs, fire pokers and many different things. I had a bit of money saved up from work and I decided to get the stuff needed to make a propane forge and bought an anvil too.  My dad had a MIG welder and there were different things that needed to be made with it for use around the property (ex. racks to load wood into our trucks). Over time I ended up really enjoying forging out fireplace stands as well as making a few knives. I became pretty comfortable working with metal in this way and was able to get a good feel for the material that I was working with. I even picked up a metallurgy book to learn about different material properties of steel and to be able to read the charts to properly be able to heat treat different alloys of steels. ",
    text1: "",
    text2: "",
    img:  knife
  }

  let bigTileThree = {
    title:"Work Experience" ,
    text0: "Lorenzo’s Italian Restaurant 2/*/2022 – 6/*/2022 \n Apprentice for Electrician part time commercial and residential for Tradewinds Electric (3 years)	\n Carpentry side jobs: framing, roofing, tile, carpet, roofing, concrete.\n Highschool diploma through Penn Foster (Graduated: 8/6/18) \n New Mexico HSE (Graduated: 9/8/20)\n Started college at NNMC for one semester continuing at NMSU for bachelor’s degree in computer science (graduation estimated date 12/1/2024)\n Currently taking introduction to data-structures",
    text1: "",
    text2: "",
    img:  null
  }

  let bigTileFour = {
    title: "Computer Science Background",
    text0:  "   I think it was around the age of fourteen that my interest in how computers worked started. The way I remember it. I had a garbage laptop from like 2008 that I couldn’t even run Minecraft on it without lagging (probably had like 2 GB RAM).  This made me want to learn why this black box struggled to run my favorite game at the time and try to make it better. I started to learn different parts of the computer, then that there was something called programming was how all software was made. Anyways from there many questions sparked and I wanted answers. I loved the logic that went into higher level languages maybe because it reminded me of math, or perhaps it just made kid me feel smart.",
    text1:  "   I was probably around sixteen or seventeen when I actually tried to work with different platforms like Unity and Arduino microcontrollers. Granted most projects were dipped in projects but they led me to grasp the fundamentals. About 17-19 I decided to get a lot more serious and actually buy some books and started off with the C++ language. This language taught me a lot with all of the dedicated programming channels on YouTube. I did some more basic projects in unity and looking into what OpenGL is.  I learned about how memory worked with the language and what was happening to variables and how they were stored and how they are referenced as well as decently in-depth stuff about the languages. Around that time AI and machine learning had gained a lot of popularity and praise, Boston Dynamics also caught my eye, and I increasingly became more interested in this area and field. ",
    text2:  "   From there covid had just hit I decided to quit my job as an apprentice electrician and start pursuing a bachelor’s degree in computer science. In my past college years, I worked with things like OpenCV, PyCharm, and TensorFlow. Many basic things of course. About a year ago I learned the basic theory behind a feed-forward neural network and the calculus behind it. Although I didn’t know enough multivariable calculus and matrix math, I tried to look into what it would take to implement one from scratch without the use of an interface/API. Although I couldn’t fully understand how to do it, the project gave me a great understanding of neural networks and appreciation of the amount of power that they have. Within the last semesters I’ve taken a basic lower-level assembly language class and now last semester an introduction to data structures class. From all of this I hope this shows that I have the experience needed and learning style needed be able to excel and bring worth in my field of study and work.  ",
    img:  pcb
  }


  let bigTileFive = {
    title: 'What’s a computer scientist if they can’t build their own website? It’s not 100% done yet but over the course of 5 days and about a month’s work of studying html, CSS, JavaScript, and React. I decided to finally put together my own custom-built single page website/portfolio. I bought a web development course on Udemy about 2 weeks before I started this project and watched many YouTube tutorials. I hope to be able to potentially get some work in this sector. As the weeks continue, I will also learn more about how to build a website linked to a database and potentially some basics in mongoose.',
    text0: '',
    text1: '',
    text2: '',
    img: null

  }


  

  let [isHidden, setIsHidden] = useState(false);


  let hideAll = () => {
    setIsHidden(!isHidden);
  }



  return(
    <div className='projects' id='projects'>
      <div className='projects-header'>
        <h1>Projects and Experience</h1>  
      </div>
      <div className='projects-tiles_container'> 

        <div onClick={hideAll}><Growtile tileOBJ={tileOne} bigTileOBJ={information} isHide={isHidden}/></div>
        <div onClick={hideAll}><Growtile tileOBJ={tileTwo} bigTileOBJ={bigTileTwo} isHide={isHidden}/> </div>
        <div onClick={hideAll}><Growtile tileOBJ={tileThree} bigTileOBJ={bigTileThree} isHide={isHidden}/></div>
        <div onClick={hideAll}><Growtile tileOBJ={tileFour} bigTileOBJ={bigTileFour} isHide={isHidden}/></div>
        <div onClick={hideAll}><Growtile tileOBJ={tileFive} bigTileOBJ={bigTileFive} isHide={isHidden}/></div>
        
      </div>
      {/* <Bigtile object={information}/> */}
    </div>
  )
}

export default Projects