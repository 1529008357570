import React, {useState} from 'react'
import {twist, dragon, code, workPic} from '../index'
import './header.css'







const Header = () => {

  const [currentPic, setCurrentPic] = useState(twist);
  const [count, setCount] = useState(0);
  

  let nextImage = () => {
    setCount(count + 1);
    
    switch(count){
      case 0: setCurrentPic(code);
      break;
      case 1: setCurrentPic(dragon);
      break;
      case 2: setCurrentPic(twist);
      break;
      case 3: setCurrentPic(workPic);
      break;
      default: ;

    }
     if(count > 3) setCount(0);
  }

  setTimeout(nextImage, 5000);


  return ( 
    <div className='header' id='home' >
      <div className='header-content'>
        <h1>Isaiah Malcom</h1>
      </div>
       
      <div className='header-content-image'>
          <img className='shadow-drop-2-center header-content-image_img'  src={currentPic}/>
      </div>
      
    </div>
  
  )
}


export default Header
