import React from 'react'
import './connect.css'

const Connect = () => {
  return(
    <div className='connect' id='connect'>  
        <h1>Connect</h1>
        <a href='https://www.linkedin.com/in/isaiah-malcom-698729223/'><p>LinkedIn</p></a>
        <a href='https://www.instagram.com/pennpaper_ideas/'><p>Instagram</p></a>
    </div>
  )
}

export default Connect