import './App.css';
import RoundGlow from './components/roundglow/RoundGlow';
import { Header, Navbar, Projects, Connect } from './containers';
import Aboutme from './containers/aboutme/Aboutme';


function App() {
  return (
    <div className='app'> 
      <div className='gradient__bg'>
        <Navbar/>
      </div>
      <Header/>
      <RoundGlow/>
      <RoundGlow/>
      <RoundGlow/>
      <Projects/>
      <Aboutme/>
      <Connect/> 
      <div className='footer-div'></div>
    </div>
    
  );
}

export default App;
