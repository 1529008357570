import React from 'react'
import './bigtile.css'

const Bigtile = ({object}) => {
  return (
    <div className='bigtile scale-up-center blueGradient__bg-two'>

      <div className='bigtile-document'>  
        <h1>{object.title}</h1>
        <p>{object.text0}</p>
        <p>{object.text1}</p>
        <p>{object.text2}</p>
      </div>
      <div className='bigtile-img '>
        <img className='shadow-drop-2-center' src={object.img}/>
      </div>
    </div>
  )
}

export default Bigtile