import React from 'react'
import './tile.css'


const Tile = ({text, title, hide}) => {
  return (
    <div className='tile blueGradient__bg slide-in-fwd-center' style={hide ? {display: "none"} : {display: 'flex'}}>
      
        <h1>{title}</h1>
        <p>{text}</p>
         
    </div>
   
  )
}

export default Tile