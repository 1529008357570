import React, {useState} from 'react'
import './growtile.css'
import Tile from '../../components/tile/Tile';
import Bigtile from '../../components/bigtile/Bigtile';

const Growtile = ({tileOBJ, bigTileOBJ, isHide}) => {
  const [isTile, setIsTile] = useState(true);
    
    let expand = () => {
        setIsTile(!isTile);
    }

    return (
    <div className='growtile' onClick={expand}>
       {isTile ? <Tile title={tileOBJ.title} text={tileOBJ.text} hide={isHide}/> : <Bigtile object={bigTileOBJ}/>}  
    </div>
  )
}

export default Growtile