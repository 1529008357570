import React, {useState} from "react";
import "./roundglow.css";

const RoundGlow = () => {
   
   let num = Math.random() * 800
   let num2 = Math.random() * 800
   

   const [glowStyle, setGlowStyle] = useState({
      top: num.toString() + "px",
      right: num2.toString() + "px"
   })  
 

   

   let newPos = () =>{    
      num = Math.random() * 800
      num2 = Math.random() * 1200


      if(Math.random() > 0.5 ){
         
         setGlowStyle({
            top: num.toString() + "px",
            right: num2.toString() + "px",
               
         })

      }else{
         
         setGlowStyle({
            top: num.toString() + "px",
            left: num2.toString() + "px",
             
         })
      }
   }
   
    setTimeout(newPos, 10000);

   return(
   <div className='roundGlow' style={glowStyle}/>
   )
}


export default RoundGlow;